<template>
	<div class="page" id="login">
		<div class="gauche">
			<div class="top">
				<img src="../assets/logovertical.png" id="logo" />
			</div>
			<div class="center">
				<h2>Manage your Easyflat's offers</h2>
				<h1><span class="semibold blue">Log in</span> your account</h1>
				<form id="login_form" @submit.prevent="handleSubmit()">
					<div class="group">
						<label for="username">E-mail</label>
						<div class="line">
							<i class="fal fa-envelope"></i>
							<input type="email" id="username" required v-model="username" />
						</div>
					</div>
					<div class="group">
						<label for="password">Password</label>
						<div class="line">
							<i class="fal fa-key"></i>
							<input type="password" id="password" required v-model="password" />
						</div>
					</div>
					<div class="group group2">
						<button type="submit">{{ login_button_text }}</button>
						<div class="item">
							<div @click="forgot()">Forgot password ?</div>
						</div>
					</div>
					<div class="reponse" v-if="reponse.length > 0">{{ reponse }}</div>
				</form>
			</div>
			<div class="bottom">
				<div>
					<a href="#">General conditions of use</a>
					<span>© 2019 - {{ new Date().getFullYear() }} Easyflat 1982 | All rights reserved</span>
					<br /><span>Version {{ version }}</span>
				</div>
				<a href="https://www.easyflat-1982.com">Visit EasyFlat website</a>
			</div>
		</div>
		<div class="droite"></div>
	</div>
</template>
<script>
export default {
	name: "Connexion",
	components: {},
	data() {
		return {
			username: "",
			password: "",
			reponse: "",
			version: "",
			login_button_text: "Login",
		};
	},
	mounted() {
		this.version = process.env.VUE_APP_VERSION;
	},
	methods: {
		handleSubmit() {
			this.login_button_text = "Processing...";
			this.axios({
				url: "https://agents.easyflat-1892.com/api/login/",
				method: "post",
				params: {
					username: this.username,
					password: this.password,
				},
			})
				.then((r) => r.data)
				.then((res) => {
					if (res.respond == 0) {
						this.reponse = res.message;
						this.login_button_text = "Login";
					} else {
						var can_access = res.result[0].custom_fields.peut_acceder_au_generateur_doffre;
						if (can_access) {
							this.$store.commit("setUser", res.result[0]);
							this.$store.commit("setConnected", true);
							this.$router.push({ path: "/" });
						} else {
							this.login_button_text = "Login";
							this.reponse = "Sorry, you don't have authorization to access this platform.";
						}
					}
				});
		},
		forgot() {
			if (this.username.length == 0) {
				this.reponse = "Please, enter your username ";
			} else {
				this.axios({
					url: "https://agents.easyflat-1892.com/api/lostpwd/",
					method: "",
					params: {
						email: this.username,
					},
				})
					.then((r) => r.data)
					.then((res) => {
						if (res.respond == 0) {
							this.reponse = res.message;
						} else {
							this.reponse = "Recover e-mail sent successfully";
						}
					});
			}
		},
	},
};
</script>

<style lang="less">
#login {
	display: flex;
	flex-wrap: wrap;
	min-height: 100vh;
	transform: translateY(-80px);
	.gauche {
		flex: 0 0 50%;
		padding: 50px;
		background: white;
		display: flex;
		flex-direction: column;
		@media screen and (max-width: 768px) {
			flex: 0 0 100%;
			padding: 30px;
		}
		.top {
			flex: 0 0 auto;
			padding: 0;
			@media screen and (max-width: 768px) {
				text-align: center;
			}
			#logo {
				width: 150px;
				height: auto;
				@media screen and (max-width: 768px) {
					width: 100px;
				}
			}
		}
		.bottom {
			font-size: 13px;
			color: lighten(black, 30%);
			display: flex;
			align-items: flex-end;
			@media screen and (max-width: 768px) {
				flex-direction: column;
				align-items: center;
			}
			div {
				flex: 1;
				padding-right: 100px;
				@media screen and (max-width: 768px) {
					padding: 30px 10px;
					text-align: center;
				}
			}
			a {
				display: block;
				flex: 0 0 auto;
				color: #312483;
				font-weight: 500;
			}
		}
		.center {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: left;
			width: 460px;
			margin: 0 auto;
			@media screen and (max-width: 768px) {
				width: 100%;
				text-align: center;
				margin-top: 30px;
			}
			h2 {
				font-size: 16px;
				text-transform: uppercase;
				font-weight: 400;
				letter-spacing: 0.03em;
				margin: 0 0 10px 0;
				color: lighten(black, 40%);
				@media screen and (max-width: 768px) {
					font-size: 12px;
					margin: 0;
				}
			}
			h1 {
				margin: 0;
				font-size: 36px;
				font-weight: 500;
				line-height: 40px;
				margin-bottom: 30px;
				@media screen and (max-width: 768px) {
					font-size: 26px;
					line-height: 32px;
				}
			}
			.reponse {
				color: red;
				font-weight: 500;
				text-align: left;
				font-size: 13px;
				padding: 20px 0 0 0;
			}
			.group {
				display: block;
				width: 100%;
				margin-bottom: 15px;
				label {
					font-size: 14px;
					padding: 10px;
					display: block;
					font-weight: normal;
				}
				.line {
					display: block;
					width: 100%;
					position: relative;
					i {
						color: lighten(black, 80%);
						font-size: 20px;
						position: absolute;
						left: 15px;
						top: 10px;
					}
					input[type="email"],
					input[type="text"],
					input[type="password"] {
						border: none;
						outline: none;
						width: 100%;
						height: 40px;
						line-height: 40px;
						background: white;
						padding-left: 50px;
						border: solid 1px lighten(black, 80%);
						border-radius: 20px;
						font-size: 16px;
						font-weight: 500;
						color: lighten(black, 60%);
						transition: all 0.3s ease;
						&:focus {
							color: black;
							border-color: #312483;
							box-shadow: 0px 2px 10px fadeOut(#312483, 60%);
						}
					}
				}
				button[type="submit"] {
					height: 40px;
					border: solid 2px #312483;
					transition: all 0.3s ease;
					color: #312483;
					font-weight: bold;
					background: white;
					padding: 0 50px;
					font-size: 16px;
					border-radius: 20px;
					outline: none;
					margin-top: 20px;
					cursor: pointer;
					&:hover {
						background: #312483;
						color: white;
						box-shadow: 0px 2px 10px fadeOut(#312483, 60%);
					}
				}
				&.group2 {
					display: flex;
					align-items: center;
					.item {
						padding-left: 30px;
						margin-top: 20px;
						font-size: 13px;
						color: lighten(black, 60%);
						cursor: pointer;
						transition: all 0.3s ease;
						&:hover {
							color: lighten(black, 20%);
						}
					}
				}
			}
		}
		.bottom {
			flex: 0 0 auto;
		}
	}
	.droite {
		flex: 0 0 50%;
		background-image: url(../assets/login.jpg);
		background-size: cover;
		background-position: center;
		@media screen and (max-width: 768px) {
			flex: 0 0 100%;
			height: 50vh;
		}
	}
}
</style>
