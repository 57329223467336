<script>
import Search from "../components/Search.vue";
import OfferCreator from "../components/OfferCreator.vue";
import { mapMutations } from "vuex";
export default {
      name: "Navigation",
      components: { Search, OfferCreator },
      props: {
            user: {
                  type: Object,
                  required: true,
            },
      },
      data() {
            return {
                 logout_visible: false
            };
      },
      methods: {
            handleLogOut() {
                  this.$store.commit("disconnect");
                  this.$router.push("/login");
            },
            ...mapMutations(["mutate_createofferOpen"]),
      }
};
</script>
<template>
      <div id="header">
            <div class="container">
                  <div class="gauche">
                        <div class="logo">
                              <router-link to="/">
                                    <img src="../assets/logovertical.png" />
                              </router-link>
                        </div>
                        <div class="links">
                              <router-link class="main-links" to="/">
                                    <strong>Dashboard</strong>
                              </router-link>

                              <router-link class="main-links" to="/customers">
                                    <strong>Customers</strong>
                              </router-link>
                        </div>
                  </div>
                  <div class="droite">
                        <button id="new_offre" @click="mutate_createofferOpen(true)" v-if="$store.state.machines_ready"><i class="fal fa-plus"></i> Create new offer</button>
                        <Search />
                        <div class="user_info" @mouseleave="logout_visible = false;">
                              <div class="avatar">
                                    <img loading="lazy" :src="user.avatar" @click="logout_visible = !logout_visible"/>
                              </div>
                              <div :class="`${logout_visible ? 'popup_user visible' : 'popup_user'}`">
                                    <div class="user_name">{{ this.$store.state.user.display_name }}</div>
                                    <div class="email">{{ this.$store.state.user.user_email }}</div>
                                    <button @click="handleLogOut()" class="logout">Logout</button>
                              </div>
                        </div>
                  </div>
            </div>
            <OfferCreator />
      </div>
</template>
<style lang="less">
#header {
      position: fixed;
      top: 0;
      width: 100%;
      background: white;
      z-index: 999;
      height: 64px;
      padding: 0 60px;
      font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
      .container {
            widows: 100%;
            max-width: 1600px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .gauche {
                  flex: 1;
                  display: flex;
                  align-items: stretch;
                  gap: 40px;
                  .logo {
                        flex: 0 0 auto;
                        padding: 5px 0;
                        img {
                              height: 50px;
                        }
                  }
                  .links {
                        display: flex;
                        gap: 40px;
                        align-items: stretch;
                        a {
                              display: flex;
                              align-items: center;
                              border-bottom: solid 0px #88aabd;
                              transition: all .4s ease;
                              border-bottom: solid 2px transparent;
                              &:hover {
                                    border-bottom: solid 2px #88aabd;
                              }
                              &.router-link-exact-active {
                                    border-bottom: solid 2px #312483;
                              }
                              strong {
                                    font-weight: 500;
                                    font-size: 0.875rem;
                              }
                        }

                  }
            }
            .droite {
                  flex: 0 0 auto;
                  display: flex;
                  align-items: center;
                  gap: 20px;
                  #new_offre {
                        font-weight: 600;
                        font-size: 0.875rem;
                        cursor: pointer;
                        line-height: 1.25rem;
                        background: #312483;
                        color: white;
                        border: none;
                        outline: none;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        border-radius: 5px;
                        column-gap: 0.375rem;
                        display: inline-flex;
                        align-items: center;
                        transition: all .4s ease;
                        
                        &:hover {
                              background: darken(#312483, 10%);
                        }
                  }
                  .user_info {
                        position: relative;
                        .avatar {
                              border-radius: 50px;
                              width: 44px;
                              height: 44px;
                              border: solid 1px rgb(0 0 0 / 10%);
                              padding: 2px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              img {
                                    width: 40px;
                                    height: 40px;
                                    object-fit: cover;
                              }
                        }
                        .popup_user {
                              position: absolute;
                              top: 100%;
                              right: -20px;
                              background: white;
                              border-radius: 15px;
                              box-shadow: 0 0 10px rgb(0 0 0 / 10%);
                              padding: 20px;
                              display: none;
                              z-index: 999;
                              width: min-content;
                              .user_name {
                                    font-weight: 600;
                                    font-size: 0.875rem;
                                    line-height: 1.25rem;
                                    color: #111827;
                              }
                              .email {
                                    font-size: 0.75rem;
                                    line-height: 1rem;
                                    color: #6B7280;
                                    padding-bottom: 20px;
                              }
                              .logout {
                                     -webkit-text-size-adjust: 100%;
                                    tab-size: 4;
                                    -webkit-tap-highlight-color: transparent;
                                    -webkit-font-smoothing: antialiased;
                                    box-sizing: border-box;
                                    border-width: 0;
                                    border-style: solid;
                                    border-color: #e5e7eb;
                                    font-family: inherit;
                                    font-feature-settings: inherit;
                                    font-variation-settings: inherit;
                                    margin: 0;
                                    text-transform: none;
                                    cursor: pointer;
                                    -webkit-appearance: button;
                                    background-image: none;
                                    border-radius: 0.375rem;
                                    background-color: rgb(255 255 255 / 1);
                                    padding-left: 0.75rem;
                                    padding-right: 0.75rem;
                                    padding-top: 0.5rem;
                                    padding-bottom: 0.5rem;
                                    font-size: 0.875rem;
                                    line-height: 1.25rem;
                                    font-weight: 600;
                                    color: rgb(17 24 39 / 1);
                                    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset, rgb(209, 213, 219) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
                                    &:hover {
                                          box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset, #312483 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
                                    }
                              }
                              &.visible {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 4px;
                              }
                        }
                  }
            }
      }
}
</style>