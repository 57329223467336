<script>
import moment from "moment";
import { mapMutations, mapActions } from "vuex";
      export default {
            name: "OfferCreator",
            data() {
                  return {
                        find_customer_quickly: '',
                        quick_customer_focus: false,
                        find_machine_quickly: '',
                        quick_machine_focus: false,
                        is_create: false,
                        nouvelle_offre: {
                              id_offre: moment().format("YYYYMMDD") + "-" + "WEB" + moment().format("hmm"),
                              id_commercial: parseInt(this.$store.state.user?.ID) || null,
                              client: null, // format []
                              machine: null, // format []
                              theme: "recQyr4kEjgcMfGQh", // format []
                              langue: "en",
                              statut: "draft",
                              devise: "EUR",
                              unite: "metre",
                              sous_total_machines: 0,
                              sous_total_controls: 0,
                              sous_total_accessoires: 0,
                              total: 0,
                              gamme_de_prix: "recyzwasXzHqcFmU5", // format []
                              page2_contact_commercial: `<h3>${this.$store.state.user.custom_fields.societe}</h3><p>${this.$store.state.user.custom_fields.adresse}<br />Tel. ${this.$store.state.user.custom_fields.numero_de_telephone}<br />Email. ${this.$store.state.user.user_email}</p>` || null,
                              page2_demande: "Enter text here", // String
                              page2_intitule: "-",
                              nom_commercial: this.$store.state.user.display_name || null, // Commercial display name
                              nom_societe: this.$store.state.user.custom_fields.societe || null, // Commercial.custom_fields.societe
                        }
                  };
            },
            methods: {
                  ...mapActions(["createOffer"]),
                  ...mapMutations(["mutate_createofferOpen"]),
                  resetData() {
                        this.find_customer_quickly = '';
                        this.quick_customer_focus = false;
                        this.find_machine_quickly =  '';
                        this.quick_machine_focus = false;
                        this.nouvelle_offre = {
                              id_offre: moment().format("YYYYMMDD") + "-" + "WEB" + moment().format("hmm"),
                              id_commercial: parseInt(this.$store.state.user?.ID) || null,
                              client: null, // format []
                              machine: null, // format []
                              theme: "recQyr4kEjgcMfGQh", // format []
                              langue: "en",
                              statut: "draft",
                              devise: "EUR",
                              unite: "metre",
                              sous_total_machines: 0,
                              sous_total_controls: 0,
                              sous_total_accessoires: 0,
                              total: 0,
                              gamme_de_prix: "recyzwasXzHqcFmU5", // format []
                              page2_contact_commercial: `<h3>${this.$store.state.user.custom_fields.societe}</h3><p>${this.$store.state.user.custom_fields.adresse}<br />Tel. ${this.$store.state.user.custom_fields.numero_de_telephone}<br />Email. ${this.$store.state.user.user_email}</p>` || null,
                              page2_demande: "Enter text here", // String
                              page2_intitule: "-",
                              nom_commercial: this.$store.state.user.display_name || null, // Commercial display name
                              nom_societe: this.$store.state.user.custom_fields.societe || null, // Commercial.custom_fields.societe
                        }
                  },
                  handleSubmit() {
                        // On regarde si c'est un admin qui créé
                        if( this.user.role.administrator) {
                              const commercial = this.$store.state.commerciaux.find(commercial => commercial.ID == this.nouvelle_offre.id_commercial);
                              this.nom_commercial = commercial.display_name;
                              this.nom_societe = commercial.custom_fields.societe;
                              this.nouvelle_offre.page2_contact_commercial = ` <h3>${commercial.custom_fields.societe}</h3><p>${commercial.custom_fields.adresse}<br />Tel. ${commercial.custom_fields.numero_de_telephone}<br />Email. ${commercial.user_email}</p> `;
                        }

                        // ON met en cage les champs qu'il faut
                        this.nouvelle_offre.client = [this.nouvelle_offre.client];
                        this.nouvelle_offre.machine = [this.nouvelle_offre.machine];
                        this.nouvelle_offre.theme = [this.nouvelle_offre.theme];
                        this.nouvelle_offre.gamme_de_prix = [this.nouvelle_offre.gamme_de_prix];

                        this.createOffer(this.nouvelle_offre).then((res) => {
                              this.mutate_createofferOpen(false);
                              this.resetData();
                              this.$router.push("/offer/" + res.id + "/" + encodeURIComponent(res.fields.machine_name[0]));
                        })
                        .catch((error) => {
                              this.resetData();
                              console.log(error);
                        });
                  },
                  handleClientSelection(customer) {
                        if(customer.id)
                        {
                              this.nouvelle_offre.client = customer.id;
                              setTimeout(() => {
                                    this.quick_customer_focus = false;
                                    this.find_customer_quickly = customer.fields.nom;
                              }, 200);
                        }
                       
                  },
                  handleMachineSelection(machine) {
                        if(machine.id) {
                              this.nouvelle_offre.machine = machine.id;
                              setTimeout(() => {
                                    this.find_machine_quickly = machine.fields.name;
                                    this.quick_machine_focus = false;
                              }, 200);
                        }
                  },
                  hide_customer_quick_focus() {
                        setTimeout(() => {
                              this.quick_customer_focus = false;
                        }, 100);
                  },
                  hide_machine_quick_focus() {
                        setTimeout(() => {
                              this.quick_machine_focus = false;
                        }, 100);
                  }

            },
            computed: {
                  open() {
                        return this.$store.state.createofferOpen;
                  },
                  user() {
                        return this.$store.state.user;
                  },
                  customers() {
                        return this.$store.getters.clients;
                  },
                  machines() {
                        return this.$store.state.machines;
                  },
                  themes() {
                        return this.$store.state.images;
                  },
                  languages() {
                        return this.$store.state.languages;
                  },
                  currencies() {
                        return this.$store.state.currencies;
                  },
                  units() {
                        return this.$store.state.units;
                  },
                  prices() {
                        return this.$store.state.prices;
                  },
                  offer() {
                        return this.$store.state.offer;
                  },
                  filtered_customers() {
                        if(this.find_customer_quickly.length < 3) {
                              return this.customers;
                        } 
                        return this.customers.filter(cus => cus.fields.nom.toLowerCase().includes(this.find_customer_quickly.toLowerCase()))
                  },
                  filtered_machines() {
                        if (this.find_machine_quickly.length < 3) {
                              return this.machines;
                        }
                        return this.machines.filter(mac => mac.fields.name.toLowerCase().includes(this.find_machine_quickly.toLowerCase()))
                  }
            },
      }
</script>
<template>
      <aside id="offer-creator" :class="open == true ? 'visible': 'hide'" >
            <div class="top">
                  <h3>Create new offer</h3>
                  <p>Please fill the form below to create a brand new offer</p>
                  <div class="close" @click="mutate_createofferOpen(false)">
                       <i class="fal fa-times"></i>
                  </div>
            </div>
            <form class="form" @submit.prevent="handleSubmit()">
                  <div class="form-group" v-if="this.user.role.administrator">
                        <label for="id_offre">Offer ID</label>
                        <input type="text" name="id_offre" id="id_offre" v-model="nouvelle_offre.id_offre" required />
                  </div>
                  <div class="form-group">
                        <label for="client">Customer</label>
                        <div class="fake_autocomplete">
                              <input type="search" @focus="quick_customer_focus = true" v-model="find_customer_quickly" />
                              <div class="recommandations" v-if="quick_customer_focus">
                                    <div v-for="customer in filtered_customers" :key="`quick-${customer.id}`" class="line">
                                          <div class="item">{{ customer.fields.nom }}</div>
                                          <div class="add" @click="handleClientSelection(customer)">
                                                select
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <div class="form-group machine">
                        <div class="gauche">
                              <label for="machine">Machine</label>
                              <div class="fake_autocomplete">
                                    <input type="search" @focus="quick_machine_focus = true" v-model="find_machine_quickly" />
                                    <div class="recommandations" v-if="quick_machine_focus" >
                                          <div v-for="machine in filtered_machines" :key="`quick-${machine.id}`" class="line">
                                                <div class="item">{{ machine.fields.name }}</div>
                                                <div class="add" @click="handleMachineSelection(machine)">
                                                      select
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="machine_preview">
                              <img v-show="nouvelle_offre.machine" :src="machines.find(machine => machine.id == nouvelle_offre.machine)?.fields?.image_machine[0]?.thumbnails?.large?.url" alt="" />
                        </div>
                  </div>
                  <div class="form-group machine">
                        <div class="gauche">
                              <label for="theme">Theme</label>
                              <select name="theme" id="theme" v-model="nouvelle_offre.theme" required>
                                    <option value="" disabled selected>Select a theme</option>
                                    <option v-for="theme in themes" :key="theme.id" :value="theme.id" placeholder="Choose theme">{{ theme.fields.Name }}</option>
                              </select>
                        </div>
                       <div class="machine_preview" v-if="themes">
                              <img v-show="nouvelle_offre.theme" :src="themes.find(theme => theme.id == nouvelle_offre.theme)?.fields?.image[0]?.thumbnails?.large?.url" alt="" />
                        </div>
                  </div>
                 
                  <div class="form-group">
                        <label for="langue">Language</label>
                        <select name="langue" id="langue" v-model="nouvelle_offre.langue" required>
                              <option value="" disabled selected>Select a language</option>
                              <option v-for="language in languages" :key="language.id" :value="language.value" placeholder="Choose language">{{ language.label }}</option>
                        </select>
                  </div>
                  <div class="form-group">
                        <label for="devise">Currency</label>
                        <select name="devise" id="devise" v-model="nouvelle_offre.devise" required>
                              <option value="" disabled selected>Select a currency</option>
                              <option v-for="(val, currency) in currencies" :key="val" :value="currency" placeholder="Choose currency">{{ currency }}</option>
                        </select>
                  </div>
                  <div class="form-group">
                        <label for="unite">Unit</label>
                        <select name="unite" id="unite" v-model="nouvelle_offre.unite" required>
                              <option value="metre">Metrics</option>
      				<option value="pouces">US</option>
                        </select>
                  </div>
                  <div class="form-group">
                        <label for="gamme_de_prix">Price range</label>
                        <select name="gamme_de_prix" id="gamme_de_prix" v-model="nouvelle_offre.gamme_de_prix" required>
                              <option value="" disabled selected>Select a price range</option>
                              <option v-for="price in prices" :key="price.id" :value="price.id" placeholder="Choose price range">{{ price.fields.Name }}</option>
                        </select>
                  </div>
                  <div v-if="this.user.role.administrator">
                        <div class="form-group restricted">
                              <label for="choose_user">Create offer for</label>
                              <select name="choose_user" id="choose_user" v-model.number="nouvelle_offre.id_commercial">
                                  <option value="" disabled selected>Select a user</option>
                                  <option v-for="user in this.$store.state.commerciaux" :key="user.ID" :value="user.ID" placeholder="Choose user">{{ user.display_name }} - <small>{{ user.custom_fields.societe }}</small></option>
                              </select>   
                        </div>
                  </div>
                  <div class="buttons" v-if="!is_create">
                        <button type="button" class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="mutate_createofferOpen(false)">Cancel</button>
                        <button type="submit" class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                  </div>
                  <div class="is_create" v-else>
                        <p>We create your offer. <br />Please wait<br /><i class="fal fa-spinner-third fa-spin"></i></p>
                  </div>
            </form>
      </aside>
</template>
<style lang="less">
      #offer-creator {
            position: fixed;
            top: 0;
            right: 0;
            width: 90%;
            height: 100vh;
            max-width: 500px;
            background: white;
            font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            box-shadow: 0px 0px 50px fadeOut(#88aabd, 80%);
            transition: all .3s ease-out;
            transform: translateX(100%);
            opacity: 1;
            pointer-events: none;
            .top {
                  padding: 30px;
                  background: fadeOut(#88aabd, 90%);
                  display: flex;
                  flex-direction: column;
                  gap: 6px;
                  h3 {
                        color: rgb(17, 24, 39);
                        font-weight: 600;
                        line-height: 1.5rem;
                        font-size: 1rem;
                        margin: 0;
                  }
                  p {
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                        color: rgb(107 114 128);
                        margin: 0;
                  }
                  .close {
                        position: absolute;
                        top: 30px;
                        right: 30px;
                        cursor: pointer;
                        &:hover {
                              color: #312483;
                        }
                  }
            }
            .form {
                  padding: 30px;
                  .fake_autocomplete {
                        position: relative;
                        input[type="text"] {
                               -webkit-text-size-adjust: 100%;
                              tab-size: 4;
                              -webkit-tap-highlight-color: transparent;
                              -webkit-font-smoothing: antialiased;
                              pointer-events: auto;
                              box-sizing: border-box;
                              border-style: solid;
                              font-family: inherit;
                              font-feature-settings: inherit;
                              font-variation-settings: inherit;
                              font-weight: inherit;
                              margin: 0;
                              appearance: none;
                              background-color: #fff;
                              border-color:  rgb(107, 114, 128);
                              padding-right: 0.75rem;
                              padding-left: 0.75rem;
                              display: block;
                              width: 100%;
                              outline: none;
                              border-radius: 0.375rem;
                              border-width: 0px;
                              padding-top: 0.375rem;
                              padding-bottom: 0.375rem;
                              color: rgb(17 24 39 / var(--tw-text-opacity));
                              box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset, rgb(209, 213, 219) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
                              font-size: 0.875rem;
                              line-height: 1.5rem;
                              &:focus {
                                    color: #312483;
                                    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset, #312483 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px
                              }
                        }
                        .recommandations {
                              position: absolute;
                              top: 100%;
                              left: 2px;
                              right: 2px;
                              background: rgb(255 255 255 / 80%);
                              backdrop-filter: blur(10px);
                              border-radius: 0 0 10px 10px;
                              border: solid 1px #e5e7eb;
                              border-top: none;
                              padding-top: 10px;
                              z-index: 999;
                              max-height: 300px;
                              overflow: auto;
                              box-shadow: 6px 6px 12px rgba(black, .1);
                              -webkit-overflow-scrolling: touch;
                              .line {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                     .item {
                                          flex: 3;
                                          margin: 0;
                                          padding: 8px;
                                          pointer-events: none;
                                          font-size: 13px;
                                          &:hover {
                                                background: #312483;
                                                color: white;
                                          }
                                    }
                                    .add {
                                          flex: 1;
                                          padding: 0 10px;
                                          text-align: right;
                                          text-transform: uppercase;
                                          color: #312483;
                                          font-size: 12px;
                                          font-weight: 500;
                                          cursor: pointer;
                                          &:hover {
                                                color: black;
                                          }
                                    }
                              }
                             
                        
                        }
                  }
                  .is_create {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 1.4;
                        color: #88aabd;
                  }
                  .buttons {
                        display: flex;
                        justify-content: flex-end;
                        button[type="button"] {
                              -webkit-text-size-adjust: 100%;
                              tab-size: 4;
                              -webkit-tap-highlight-color: transparent;
                              -webkit-font-smoothing: antialiased;
                              box-sizing: border-box;
                              border-width: 0;
                              border-style: solid;
                              border-color: #e5e7eb;
                              font-family: inherit;
                              font-feature-settings: inherit;
                              font-variation-settings: inherit;
                              margin: 0;
                              text-transform: none;
                              cursor: pointer;
                              -webkit-appearance: button;
                              background-image: none;
                              border-radius: 0.375rem;
                              background-color: rgb(255 255 255 / 1);
                              padding-left: 0.75rem;
                              padding-right: 0.75rem;
                              padding-top: 0.5rem;
                              padding-bottom: 0.5rem;
                              font-size: 0.875rem;
                              line-height: 1.25rem;
                              font-weight: 600;
                              color: rgb(17 24 39 / 1);
                              box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset, rgb(209, 213, 219) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
                              &:hover {
                                    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset, #312483 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
                              }
                        }
                        button[type="submit"] {
                              -webkit-text-size-adjust: 100%;
                              tab-size: 4;
                              -webkit-tap-highlight-color: transparent;
                              -webkit-font-smoothing: antialiased;
                              pointer-events: auto;
                              box-sizing: border-box;
                              border-width: 0;
                              border-style: solid;
                              border-color: #e5e7eb;
                              font-family: inherit;
                              font-feature-settings: inherit;
                              font-variation-settings: inherit;
                              margin: 0;
                              text-transform: none;
                              cursor: pointer;
                              -webkit-appearance: button;
                              background-image: none;
                              margin-left: 1rem;
                              display: inline-flex;
                              justify-content: center;
                              border-radius: 0.375rem;
                              background-color: #312483;
                              padding-left: 0.75rem;
                              padding-right: 0.75rem;
                              padding-top: 0.5rem;
                              padding-bottom: 0.5rem;
                              font-size: 0.875rem;
                              line-height: 1.25rem;
                              font-weight: 600;
                              color: white;
                              &:hover {
                                    background-color: darken(#312483, 10%);
                              }
                        }
                  }
                  .form-group {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        margin-bottom: 30px;
                        label {
                              font-weight: 500;
                              font-size: 0.875rem;
                        }
                        input {
                               -webkit-text-size-adjust: 100%;
                              tab-size: 4;
                              -webkit-tap-highlight-color: transparent;
                              -webkit-font-smoothing: antialiased;
                              pointer-events: auto;
                              box-sizing: border-box;
                              border-style: solid;
                              font-family: inherit;
                              font-feature-settings: inherit;
                              font-variation-settings: inherit;
                              font-weight: inherit;
                              margin: 0;
                              appearance: none;
                              background-color: #fff;
                              border-color:  rgb(107, 114, 128);
                              padding-right: 0.75rem;
                              padding-left: 0.75rem;
                              display: block;
                              width: 100%;
                              outline: none;
                              border-radius: 0.375rem;
                              border-width: 0px;
                              padding-top: 0.375rem;
                              padding-bottom: 0.375rem;
                              color: rgb(17 24 39 / var(--tw-text-opacity));
                              box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset, rgb(209, 213, 219) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
                              font-size: 0.875rem;
                              line-height: 1.5rem;
                              &:focus {
                                    color: #312483;
                                    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset, #312483 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px
                              }
                        }
                        select {
                              -webkit-text-size-adjust: 100%;
                              tab-size: 4;
                              -webkit-tap-highlight-color: transparent;
                              -webkit-font-smoothing: antialiased;
                              pointer-events: auto;
                              box-sizing: border-box;
                              border-style: solid;
                              font-family: inherit;
                              font-feature-settings: inherit;
                              font-variation-settings: inherit;
                              font-weight: inherit;
                              margin: 0;
                              appearance: none;
                              background-color: #fff;
                              border-color:  rgb(107, 114, 128);
                              padding-right: 0.75rem;
                              padding-left: 0.75rem;
                              display: block;
                              width: 100%;
                              outline: none;
                              border-radius: 0.375rem;
                              border-width: 0px;
                              padding-top: 0.375rem;
                              padding-bottom: 0.375rem;
                              color: rgb(17 24 39 / var(--tw-text-opacity));
                              box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset, rgb(209, 213, 219) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
                              font-size: 0.875rem;
                              line-height: 1.5rem;
                              &:focus {
                                    color: #312483;
                                    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset, #312483 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px
                              }
                        }
                        &.restricted {
                              background: fadeOut(#312483, 94%);
                              padding: 20px;
                              border-radius: 10px;
                              label {
                                    color: #312483;
                              }
                        }
                        &.machine {
                              display: flex;
                              flex-direction: row;
                              flex-wrap: nowrap;
                              gap: 20px;
                              align-items: flex-start;
                              .gauche {
                                    display: flex;
                                    flex-direction: column;
                                    flex: 1;
                                    label {
                                          margin-bottom: 5px;
                                    }
                              }
                              .machine_preview {
                                    flex: 0 0 120px;
                                    border: dashed 2px fadeOut(#88aabd, 80%);
                                    border-radius: 10px;
                                    aspect-ratio: 4/3;
                                    padding: 8px;
                                    img {
                                          width: 100%;
                                          height: 100%;
                                          object-fit: contain;
                                          border: none;
                                    }
                              }
                        }
                  }
            }
            &.visible {
                  transform: translateX(0%);
                  opacity: 1;
                  pointer-events: all;
                  box-shadow: 0px 0px 50px fadeOut(#88aabd, 60%);
            }
      }
</style>