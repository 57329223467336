<script>
import { mapActions } from "vuex";
export default {
      name: "Search",
      data() {
            return {
                  popup_visible: false,
                  search_term: null,
                  current_result: null,
                  is_searching: false,
                  results: null
            };
      },
      methods: {
            ...mapActions({
                  findOffers: "findOffers"
            }),
            formattedDate(date) {
                  const momentDate = this.$moment(date);
                  return `${momentDate.fromNow()}`;
            },
            focus_popup(){
                  this.popup_visible = true;
                  this.$nextTick(() => {
                        this.$refs.input_search_bar.focus();
                  });
            },
            find_offer() {
                  this.is_searching = true;
                  this.findOffers(this.search_term).then((res) => {
                        this.results = res;
                        this.is_searching = false;
                  }).catch((err) => {
                        alert(err);
                        this.results = [];
                        this.is_searching = false;
                  });
            },
            show_popup_search() {
                  this.$store.commit("setPopup", {
                        affichage: true,
                        type: "search",
                  });
            },
            hide_popup() {
                  this.popup_visible = false;
                  this.search_term = null;
                  this.results = null;
                  this.current_result = null;
            }
      },
};
</script>
<template>
      <div>
            <div id="dashboard-search" @click="focus_popup()">
                  <i class="far fa-search"></i>
            </div>
            <div id="screen_mask" v-if="popup_visible" @click="hide_popup()"></div>
            <div id="popup_search" v-if="popup_visible">
                  <div class="close" @click="hide_popup()">
                        Close
                  </div>
                  <div class="form-group">
                        <label for="search">
                              <i v-if="!is_searching" class="fa fa-search"></i>
                              <i v-else class="fa fa-spinner fa-spin"></i>
                        </label>
                        <input ref="input_search_bar" v-model="search_term" type="text" placeholder="Company, machine, number, theme, ..." @keypress.enter="find_offer" />
                  </div>
                  <div class="results" v-if="results">
                        <div class="no_result" v-if="!results.length">
                              No result
                        </div>
                        <div class="gauche" v-if="results.length">
                              
                              <div v-for="(result, index) in results" :key="`result${index}`" @click="current_result = result" :class="`${current_result?.id === result.id ? 'active nom' : 'nom'}`">
                                    <div class="avatar" v-html="result.fields.machine_name[0].substring(0, 3)"></div>
                                    <div>
                                          {{ result.fields.nom_client[0] }} - <span class="machine_name">{{ result.fields.machine_name[0] }}</span>
                                    </div>
                                    <i class="far fa-angle-right"></i>
                              </div>
                             
                        </div>
                        <div class="droite">
                              <div v-if="current_result && results.length">
                                    <div class="top">
                                          <img :src="current_result.fields.image_machine[0]?.thumbnails?.large?.url" loading="lazy" alt="" class="img_machine">
                                          <h3>{{ current_result.fields.nom_client[0] }}</h3>
                                          <div class="date">Created {{ formattedDate(current_result.fields.date_cree) }}</div>
                                          <div class="statut">{{ current_result.fields.statut.toUpperCase() }}</div>
                                    </div>
                                    <div class="bottom">
                                          <div class="machine line">
                                                <label>Manage by </label>
                                                <span>{{ current_result.fields.machine_name[0] }}</span>
                                          </div>
                                          <div class="details line">
                                                <label>Manage by </label>
                                                <span>{{ current_result.fields.nom_commercial }}</span>
                                          </div>
                                          <div class="details line">
                                                <label>Unique ID </label>
                                                <span>{{ current_result.fields.idclipper ? current_result.fields.idclipper : current_result.fields.id_offre }}</span>
                                          </div>
                                          <div class="price_range line">
                                                <label>Price</label>
                                                <span>{{ current_result.fields.gamme_de_prix_name[0] }}</span>
                                          </div>
                                          <div class="price_range line">
                                                <label>Theme</label>
                                                <span>{{ current_result.fields.theme_name[0] }}</span>
                                          </div>
                                          <router-link :to="'/offer/' + current_result.id + '/' + current_result.fields.machine_name[0]" class="link_to_offer">
                                                <span v-if="current_result.fields.statut == 'send'"><i class="far fa-file-pdf"></i> View PDF</span>
                                                <span v-else><i class="far fa-pen-to-square"></i>  Edit offer</span>
                                          </router-link> 
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
            
      </div>
     
</template>
<style lang="less">
//animation on apparition with vue transition
//https://vuejs.org/v2/guide/transitions.html
#screen_mask {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: rgba(white, .5);
      z-index: 8887;
      backdrop-filter: blur(6px);
}
#popup_search {
      position: fixed;
      top: 10vh;
      z-index: 8888;
      width: 90%;
      max-width: 768px;
      margin: 0 auto;
      left: 50%;
      font-size: 14px;
      transform: translateX(-50%);
      background: white;
      border-radius: 10px;
      font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 50px;
      .close {
            color: #88aabd;
            position: absolute;
            top: 24px;
            right: 20px;
            text-transform: uppercase;
            font-size: 10px;
            cursor: pointer;
            &:hover {
                  color: #312483;
            }
      }
      .form-group {
            padding: 10px 20px;
            display: flex;
            align-items: center;
            border-bottom: solid 1px rgb(229 231 235 / 50%);
            label {
                  color: #88aabd;
                  font-size: 14px;
                  flex: 0 0 auto;
            }
            input[type="text"] {
                  outline: none;
                  border: none;
                  font-size: 14px;
                  flex: 1;
                  color: black;
                  padding: 10px;
                  background: transparent;
                  &::placeholder {
                        color: #88aabd;
                  }
            }
      }
      .results {
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
            .no_result {
                  text-align: center;
                  padding: 20px;
                  width: 100%;
                  color: #88aabd;
                  font-weight: 500;
            }
            .gauche {
                  padding: 10px;
                  height: 24rem;
                  overflow: auto;
                  display: block !important;
                  width: 50%;
                  flex: 0 0 auto;
                  -webkit-overflow-scrolling: touch;
                  .nom {
                        padding: 8px 16px;  
                        cursor: pointer;
                        font-size: 14px;
                        transition: all .4s ease;
                        border-radius: 3px;
                        font-weight: normal;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 8px;
                        position: relative;
                        .avatar {
                              width: 32px;
                              height: 32px;
                              font-size: 10px;
                              background: #312483;
                              border-radius: 50%;
                              display: flex;
                              align-items: center;
                              font-weight: 500;
                              justify-content: center;
                              color: white;
                        }
                        .machine_name {
                              font-size: 12px;
                              color: #88aabd;
                              background: fadeOut(#88aabd, 90%);
                              padding: 2px 4px;
                              border-radius: 2px;
                        }
                        i {
                              opacity: 0;
                              transition: all .4s ease;
                              color: #88aabd;
                              position: absolute;
                              right: 20px;
                        }
                        &:hover, &.active {
                              background: rgb(243, 244, 246);
                              .machine_name {
                                    background: transparent;
                              }
                              i {
                                    opacity: 100;
                              }
                        }
                  }
            }
            .droite {
                  width: 50%;
                  border-left: solid 1px rgb(229 231 235 / 50%);
                  box-sizing: border-box;
                  height: 24rem;
                  overflow-y: auto;
                  -webkit-overflow-scrolling: touch;
                  flex: 0 0 auto;
                  display: block !important;
                  .top {
                        border-bottom: solid 1px rgb(229 231 235 / 50%);
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        align-items: center;
                        padding: 20px;
                        .img_machine {
                              width: 64px;
                              height: 64px;
                              object-fit: cover;
                              border-radius: 50%;
                        }
                        h3 {
                              margin: 5px 0 0 0;
                              font-size: 1.5em;
                        }
                        .date {
                              color: #88aabd;
                              font-style: italic;
                              margin-top: 2px;
                        }
                        .statut {
                              font-size: 0.75rem;
                              background: fadeOut(green, 90%);
                              color: green;
                              padding: 3px 4px;
                              border-radius: 3px;
                              font-size: .8em;
                              margin-top: 10px;
                        }
                  }
                  .bottom {
                        padding: 20px;
                        .line {
                              display: grid;
                              grid-template-columns: repeat(3,1fr);
                              grid-template-rows: auto;
                              gap: 1em;
                              padding: 5px 0;
                              font-size: 14px;
                              label {
                                    color: black;
                                    font-weight: 600;
                                    grid-column: 1;
                              }
                              span {
                                    grid-column: span 2;
                                    font-weight: normal;
                                    color: rgb(0 0 0 / 80%);
                              }
                        }
                  }
                  .link_to_offer {
                        display: block;
                        margin-top: 20px;
                        font-weight: 500;
                        padding: 10px 20px;
                        background: #312483;
                        color: white;
                        border-radius: 5px;
                        text-align: center;
                        transition: all .4s ease;
                        &:hover {
                              background: darken(#312483, 10%);
                        }
                  
                  }
            }
      }
}
#dashboard-search {
      color: #88aabd;
      font-size: 1em;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border-radius: 3px;
      transition: all .4s ease;
      cursor: pointer;
      font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      &:hover {
            border-color: #312483;
            background: fadeOut(#88aabd, 90%);
            i {
                  color: #312483;
            }
      }
}
</style>