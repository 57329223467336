<template>
	<div id="app">
		
		<div id="responsivegoout">
			<img class="logo" src="./assets/logovertical.png" />
			<span>Sorry, this app is not compatible with the resolution of your screen.</span>
		</div>
		<div id="general-loading" v-if="$store.state.freeze">
			<div class="loadingzone">
				<img class="logo" src="./assets/logovertical.png" />
				<img src="./assets/spinner.gif" />
				<span v-html="$store.state.freeze_message"></span>
			</div>
		</div>
		<router-view />
	</div>
</template>

<script>
export default {
	name: "App",
	components: {},
	data() {
		return {};
	},
	mounted() {
		console.log("patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90");
		this.$store.dispatch("getCurrencies");
		window.beforeunload = (e) => {
			e.preventDefault();
		};
		window.unload = (e) => {
			e.preventDefault();
			this.$router.push("/");
		};
	},
	methods: {},
};
</script>

<style lang="less">
* {
	box-sizing: border-box;
}
a {
	text-decoration: none;
	color: inherit;
	outline: none;
}
body {
	margin: 0;
	padding: 64px 0 0 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-weight: 400;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	background: #f4f7fa;
	.blue {
		color: #312483;
	}
	.semibold {
		font-weight: 600;
	}
	.red {
		color: #E40815;
	
	}
	.main-button {
		background: #312483;
		border: none;
		padding: 12px 40px 12px 30px;
		border-radius: 10px;
		color: white;
		font-weight: bold;
		font-size: 14px;
		outline: none;
		cursor: pointer;
		border-bottom: solid 3px darken(#312483, 10%);
		i {
			padding-right: 10px;
			font-size: 1.2em;
			transform: translateY(2px);
		}
		&:hover {
			border-bottom-width: 1px;
			background: darken(#312483, 2%);
		}
	}
	main {
		padding: 50px;
		min-height: 100vh;
		*[contenteditable="true"] {
			outline: none;
			transition: all 0.2s ease;
			&:focus {
				padding: 10px;
				box-shadow: 0 0 40px fadeOut(black, 50%);
				border-radius: 4px;
			}
		}
		h1 {
			font-size: 24px;
		}
		.logo-pied-de-page {
			position: absolute;
			bottom: 48px;
			left: 50px;
			img {
				height: 40px;
				width: auto;
			}
		}
		.pied_de_page {
			position: absolute;
			bottom: 50px;
			right: 50px;
			border: solid 2px #312483;
			left: 230px;
			color: #312483;
			font-weight: bold;
			font-size: 13px;
			display: flex;
			align-items: stretch;
			.reference,
			.date {
				padding: 6px 12px;
				flex: 0 0 auto;
			}
			.machine {
				background: #312483;
				color: white;
				padding: 6px;
				flex: 1;
				text-align: left;
				.lamachine {
					display: flex;
					justify-content: space-between;
					br {
						display: none;
					}
				}
			}
			.item {
				padding: 6px 12px;
				flex: 0 0 auto;
				font-weight: 300;
				color: lighten(black, 60%);
				font-size: 13px;
				border-left: solid 2px #312483;
			}
		}
	}
	#general-loading,
	#responsivegoout {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 9998;
		background: rgba(white, 50%);
		backdrop-filter: saturate(0%) blur(20px);
		display: flex;
		align-items: center;
		justify-content: center;
		.loadingzone {
			background: white;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			flex-direction: column;
			border: solid 1px lighten(black, 94%);
			border-radius: 3px;
			padding: 50px;
			box-shadow: 0px 12px 15px rgba(black, 6%);
			width: 500px;
			.logo {
				width: 140px;
				margin-bottom: 20px;
			}
			span {
				font-size: 20px;
				line-height: 32px;
				font-weight: 300;
				margin-top: 20px;
				strong,
				b {
					font-weight: 500;
					font-size: 30px;
				}
			}
		}
	}
	#responsivegoout {
		z-index: 99999;
		flex-direction: column;
		background: white;
		display: none;
		@media screen and (max-width: 980px) {
			display: flex;
		}
		.logo {
			width: 200px;
			margin-bottom: 40px;
		}
		span {
			font-size: 20px;
			line-height: 32px;
			font-weight: 500;
			margin-top: 20px;
			max-width: 330px;
			margin: 0 auto;
			text-align: center;
		}
	}
}

@keyframes appearFromLeft {
	0% {
		opacity: 0;
		transform: translateX(-30%);
	}
	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
