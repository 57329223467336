<script>
export default {
      name: "Tuile",
      props: {
            offre: {
                  type: Object,
                  required: true,
            },
      },
      methods: {
            formattedDate(date) {
                  const momentDate = this.$moment(date);
                  return `${momentDate.fromNow()}`;
            },
            handleRoute(e, a, b) {
                  this.$router.push({
                        name: "offer",
                        params: {
                              id: a,
                              machine: encodeURIComponent(b)
                        },
                  });
            },
      },
};
</script>
<template>
      <div class="tuile-projet">
            <div class="nom_machine">
                  <span v-html="offre.fields.machine_name[0]"></span>
            </div>
            <div class="metas">
                  <h2>{{ offre.fields?.nom_client[0] }} <span :class="`statut ${offre.fields.statut}`">{{ offre.fields.statut == "send" ? 'Generated' : 'Draft' }}</span></h2>
                  <div class="id" v-html="`${offre.fields.id_clipper && offre.fields.id_clipper != '' ? offre.fields.id_clipper : offre.fields.id_offre}`"></div>
            </div>
            <div class="factor">
                  {{ offre.fields.gamme_de_prix_name[0] }}
                  <div class="nom">Managed by <b>{{ offre.fields.nom_commercial }}</b></div>
                   <div class="ago">Modified {{ formattedDate(offre.fields.date_modif) }} </div>
            </div>
            <router-link class="action" :to="`/offer/${offre.id}/${encodeURIComponent(offre.fields.machine_name)}`">					
                  <span v-if="offre.fields.statut == 'send'"><i class="far fa-file-pdf"></i> View PDF</span>
                  <span v-else><i class="far fa-pen-to-square"></i>Edit offer</span>	
            </router-link>
      </div>
</template>
<style lang="less">
.tuile-projet {
      background: white;
      border-radius: 10px;
      box-shadow: 3px 6px 15px rgb(229 231 235 / 50%);
      transition: all .4s ease;
      padding: 10px 20px;
      display: flex;
      gap: 10px;
      align-items: flex-start;
      position: relative;
      font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      .metas {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            flex: 1;
      }
      .nom_machine {
            font-size: 16px;
            font-weight: 600;
            color: rgb(17, 24, 39);
            justify-self: flex-start;
            flex: 0 0 130px;
            padding-top: 3px;
            span {
                  background: fadeOut(#312483, 90%);
                  padding: 3px 5px;
                  color: #312483;
                  border-radius: 4px;
                  
            }
      }
      h2 {
            font-size: 18px;
            font-weight: 600;
            margin: 0 0 6px;
            transition: all .4s ease;
            color: rgb(17, 24, 39);
            display: flex;
            align-items: center;
            .statut {
                  font-size: 14px;
                  font-weight: 400;
                  margin-left: 10px;
                  padding: 2px 5px;
                  border-radius: 4px;
                  text-transform: uppercase;
                  font-size: 10px;
                  letter-spacing: .05em;
                  &.send {
                        background: fadeOut(green, 90%);
                        color: green;
                  }
                  &.draft {
                        background: fadeOut(#88aabd, 90%);
                        color: #88aabd;
                  }
                 
            
            }
      }
      .factor {
            flex: 1;
            font-weight: 500;
            font-size: 13px;
            padding: 0px;
            display: flex;
            flex-direction: column;
            gap: 2px;
            margin-top: 4px;
      }
      .nom, .id, .ago {
            font-size: 12px;
            color: rgb(17, 24, 39);
            padding: 2px 0;
            transition: all .4s ease;
            
      }
      .nom {
            color: #88aabd;
            b {
                  color: rgb(17, 24, 39);
            }
      }
      .ago {
            color: #88aabd;
            font-style: italic;
            font-size: 12px;
      }
      .date {
            position: absolute;
            top: 0;
            right: 0;
            color: white;
            background: #312483;
            /* convert to RGBa */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-radius: 0px 20px 0px 20px;
            font-size: 20px;
            font-weight: bold;
            padding: 10px;
            strong {
                  font-size: 20px;
            }
      }
      .action {
            color: rgb(17, 24, 39);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 600;
            padding: 8px;
            transition: all .4s ease;
            align-self: center;
            span {
                  align-items: center;
                  justify-content: space-between;
                  display: flex;
                  gap: 8px;
            }
      }
      &:hover {
            background: #312483;
            box-shadow: 3px 5px 10px rgba(241, 139, 0, .1);
            color: white;
            h2, .nom, .id, .ago {
                  color: white;
                  .statut {
                        background: white;
                        color: #312483;
                  }
            }
            
            .nom_machine {
                  color: white;
                  background: rgb(255 255 255 / 30%);
            }
            .action {
                  background: rgb(255 255 255 /20%);
                  border-radius: 5px;
                  color: white;
            }
      }

}
</style>