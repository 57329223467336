<template>
	<div id="not_found">
		<img src="../assets/logovertical.png" id="logo" />
		<h1><span class="blue">This page</span> cannot <span class="red">be found</span></h1>
	</div>
</template>
<script>
	export default {
		name: "PageNotFound",
		components: {},
		data() {
			return {};
		},
		methods: {}
	};
</script>

<style lang="less">
#not_found {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	box-sizing: border-box;
	gap: 20px;
	padding: 20px;
	#logo {
		max-width: 200px;
		height: auto;
	}
	h1 {
		font-size: 20px;
	}
}
</style>