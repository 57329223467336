import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Connexion from "./views/Connexion.vue";
import store from "./store";
import Compte from "./views/Compte.vue";
import PageNotFound from "./views/PageNotFound.vue";
import Clients from "./views/Clients.vue";

Vue.use(Router);

const router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			name: "home",
			component: Home,
		},
		{
			path: "/login",
			name: "login",
			component: Connexion,
		},
		{
			path: "/offre/:id/",
			name: "offre",
			component: () => import("./views/Test.vue"),
		},
		{
			path: "/offer/:id/:machine/",
			name: "offer",
			component: () => import("./views/Offre.vue"),
		},
		{
			path: "/account/:username",
			name: "account",
			component: Compte,
		},
		{
			path: "/customers/",
			name: "Customers",
			component: Clients,
		},
		{
			path: "/offres/",
			name: "Home",
			component: Home,
		},
		{
			path: "*",
			name: "Page not fount",
			component: PageNotFound,
		},
	],
});

router.beforeEach((to, from, next) => {
	if (!store.state.connected && to.path != "/login") {
		next({ path: "/login" });
	} else {
		next();
	}
	if (to.path == "/login" && store.state.connected) {
		next({ path: "/" });
	}
});

export default router;
